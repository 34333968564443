import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../../GatsbyLink';
import { Image } from '../../Image'
import '../LinkList/LinkList.scss';

const GlobalCtaPass = (props) => {
  const {data, links} = props
  const linksToUse = links ? links : data.wordpressAcfOptions.options.links
  if (!linksToUse) return null
  return (
    <section className="links-list">
      <div className="wrap">
        <div className={`links tiles`}>
          {linksToUse && linksToUse.map((link, index) => (
            <GatsbyLink className="item" key={index} to={link.link && link.link.url ? link.link.url : '#'} target={link.link && link.link.target ? link.link.target : '_self'}>
              <div className="inner">
                <div className="content">
                  <span className="title" dangerouslySetInnerHTML={{ __html: link.title }} />
                  <span className="sub-title" dangerouslySetInnerHTML={{ __html: link.subTitle }} />
                </div>
                {link.image && link.image && <div className="background"><Image src={link.image} /></div>}
              </div>
            </GatsbyLink>
          ))}
        </div>
      </div>
    </section>
  )
}

export const GlobalCta = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressAcfOptions {
            options {
              links {
                link {
                  target
                  title
                  url
                }
                title
                subTitle
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 520, quality: 70) {
                        ... GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <GlobalCtaPass {...props} data={data} />}
    />
  );
}
