import React, { useMemo } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import ProjectListItem from '../Projects/ProjectListItem'
import Carousel from '../Carousel';
import RenderContent from '../RenderContent'
import './RelatedProjects.scss'

const RelatedProjectPass = (props) => {
  const { data, title, postfilter, content } = props
  const { allWordpressWpProjects, siteMetadata = null } = data
  const posts = useMemo(() => postfilter ? allWordpressWpProjects.nodes.filter(item => postfilter.includes(item.wordpress_id) ) : allWordpressWpProjects.nodes, [postfilter, allWordpressWpProjects]);

  const settings = {
    container: 'related-projects-carousel',
    nav: true,
    mouseDrag: true,
    controls:true,
    //rewind: false,
    loop:false,
    //autoplay: false,
    gutter:20,
    responsive: {
      640: {
        items: 2,
      },
      420: {
        items: 1,
      }
    }
  };

  if (!posts || !posts.length > 0 ) return null
  
  return (
    <section className={`post-feed project-list`}>
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            {title && <div className="intro">
              <h3 dangerouslySetInnerHTML={{__html: title}} />
              {content && <RenderContent content={content} />}
            </div>}
            <div className="slider">
              <Carousel settings={settings}>
                {posts && posts.map((post, slide_count) => post.slug !== 'gatsby' &&
                  <ProjectListItem key={slide_count} data={post} siteMetadata={siteMetadata} />
                )
              }
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default (props) => (
  <StaticQuery
    //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
    query={graphql`
      query {
        allWordpressWpProjects(sort: {fields: date, order: DESC}, limit: 6, filter: {slug: {ne: "gatsby-demo"}}) {
          nodes {
            ...ProjectListFields
          }
        }
      }
    `}
    render={data => <RelatedProjectPass {...props} data={data} />}
  />
)
