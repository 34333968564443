import React from 'react';
import { RenderShortcode } from '../../RenderShortcode'
import './Content.scss';

export const Content = props => {
  const { title, content, style = 'default', layout = 'third' } = props
  return (
    <section className={`intro ${style} ${layout}`}>
      <div className="wrap">
        <div className="inner">
          {title && <div className="title"><h3 className="section-title">{title}</h3></div>}
          <div className="content">
            <RenderShortcode content={content}/>
          </div>
        </div>
      </div>
    </section>
  );
};
