import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { Image } from '../../Image';
import { ProductImage } from '../ProductImage'
import './ProductListItem.scss'
import { decodeEntities } from '../../../utils/helpers';

const ProductListItem = (props) => {
  const { siteMetadata, data, pathPrefix, showAuthor, showIntro } = props;
  const { title, path, featured_media, author, date, acf, product_category } = data
  return (
    <div className="product-list-item">
      <div>
        <div className="image">
          <GatsbyLink to={path} label={title}>
          {acf.featureImage ? <div className="feature-image"><Image src={acf.featureImage} position="relative" /></div>
          :
          <ProductImage above={acf.above_honeycomb} below={acf.below_honeycomb}/>
          }
          </GatsbyLink>
        </div>
        <div className="inner">
          <h4 className="title"><GatsbyLink to={path}>{decodeEntities(title)}</GatsbyLink></h4>
          {showIntro && acf.content &&  <RenderContent content={acf.content} />}
          {path && <GatsbyLink to={path} label={title} className="post-link">View Product</GatsbyLink>}
        </div>
      </div>
    </div>
  )
}

export default ProductListItem
