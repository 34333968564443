import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../GatsbyLink';
import { Image } from '../Image'
import RenderContent from '../RenderContent'
import { isClient } from '../../utils/helpers'
import './ColourCharts.scss';

class ColourChartsPass extends Component {
  state = {
    modalContent: null
  };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleModal(event);
  }

  toggleModal = (event, object) => {
    event.preventDefault();
    this.setState({ modalContent: object });
  }

  render() {
    const { data } = this.props
    const colours = this.props.colours || [3050]
    const { modalContent } = this.state
    const { allWordpressWpColour } = data
    const colourGroups = allWordpressWpColour.nodes.filter(item => colours.includes(item.wordpress_id) ? item.acf : null)
    if (!colourGroups) return null;
    let disclaimerContent = '';

    return (
      <div className="colour-content" >
        {modalContent &&
          <div className="colour-modal">
            <div className="inner">
              <button className="close" onClick={(event) => this.toggleModal(event, null)} />
              <div className="image"><Image src={modalContent.image} /></div>
              <h4 className="colour-title"><strong>{modalContent.colourTitle.split('-')[1]}</strong><div className="colour-code">{modalContent.colourTitle.split('-')[0]}</div></h4>
            </div>
          </div>
        }

        {colourGroups && colourGroups.map((group, index) => {
          const { groupTitle, charts, groupDownload, disclaimer } = group.acf;
          disclaimerContent = disclaimer ? disclaimer : disclaimerContent;
          return (
            <div className="group" key={index}>
              <GatsbyLink to={groupDownload.source_url} target="_blank" className="action">Download Colour Chart</GatsbyLink>
              <h4 className="group-title">{groupTitle}</h4>
              <div className="colours">
                {charts && charts.map((chart, index) =>
                  <div className="colour" key={index} onClick={(event) => this.toggleModal(event, chart)}>
                    <div className="image"><Image src={chart.image} /></div>
                    <h4 className="colour-title"><strong>{chart.colourTitle.split('-')[1]}</strong><div className="colour-code">{chart.colourTitle.split('-')[0]}</div></h4>
                  </div>
                )}
              </div>

            </div>
          )
        })}
        <RenderContent className="disclaimer" content={disclaimerContent} />
      </div >
    )
  }
}

export const ColourCharts = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpColour {
            nodes {
              wordpress_id
              acf {
                charts: colours {
                  colourTitle: colour_title
                  image {
                    localFile {
                      childImageSharp {
                        fixed(width: 350) {
                          ... GatsbyImageSharpFixed_noBase64
                        }
                      }
                    }
                  }
                }
                groupDownload : group_download {
                  source_url : url
                }
                groupTitle : group_title
                disclaimer
              }
            }
          }
        }
      `}
      render={data => <ColourChartsPass {...props} data={data} />}
    />
  );
}
