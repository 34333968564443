import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { Image } from '../../Image';
import './ProjectListItem.scss'
import { decodeEntities } from '../../../utils/helpers';
import { dateI18n } from '@wordpress/date';

const ProjectListItem = (props) => {
  const { siteMetadata, data, pathPrefix, showAuthor, dateFormat } = props;
  const { excerpt, title, slug, path, featured_media, author, date, project_category } = data
  const dateFormatted = dateFormat && date && dateI18n(dateFormat,date)
  return (
    <div className="project-list-item">
      <GatsbyLink to={path} label={title} decode={false}>
        <div className="image"><Image src={featured_media} className="background" /></div>
        <div className="inner">
          {project_category && project_category.map((cat, index) =>
            <span className="cat" key={index}>{cat.name}</span>
          )}
          <h4 className="title">{decodeEntities(title)}</h4>
          {excerpt && <RenderContent content={`${excerpt}`}/>}
          {slug && <span className="post-link">View Project</span>}
        </div>
      </GatsbyLink>
    </div>
  )
}

export default ProjectListItem
