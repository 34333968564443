import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../../GatsbyLink';
import { Image } from '../../Image'

const ApplicationsPass = (props) => {
  const {data, applications } = props
  const allApplications = data.allWordpressWpProductApplication.nodes
  return (
    <div className="applications">
      <div className="wrap">
        <div className="links">
          {applications && applications.map((app, index) => {
            const thisApplication= allApplications.filter(compare => compare.wordpress_id === app)
            if (!thisApplication[0]) return null
            return (
              <div key={index}>
                <div className="title">{thisApplication[0].name}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const Applications = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpProductApplication {
            nodes {
              name
              wordpress_id
            }
          }
        }
      `}
      render={data => <ApplicationsPass {...props} data={data} />}
    />
  );
}
