import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../../GatsbyLink';
import { Image } from '../../Image'

const AccreditationsPass = (props) => {
  const {data, accreditations } = props
  const allAccreditations = data.allWordpressWpProductAccreditation.nodes
  //console.log('accreditations:',accreditations)
  return (
    <div className="accreditations">
      <div className="wrap">
        <div className="links">
          {accreditations && accreditations.map((accr, index) => {
            const thisAaccreditation = allAccreditations.filter(compare => accr.accreditation && accr.accreditation.term_id && compare.wordpress_id === accr.accreditation.term_id)
            if (!thisAaccreditation[0]) return null
            return (
              <div key={index}>
                {thisAaccreditation[0].acf.logo && <div className="logo"><Image src={thisAaccreditation[0].acf.logo} lazy={false}/></div>}
                <div className="title">{thisAaccreditation[0].name}</div>
                {accr.certificate.source_url && <GatsbyLink to={accr.certificate.source_url}>Download</GatsbyLink>}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const Accreditations = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpProductAccreditation {
            nodes {
              acf {
                logo {
                  source_url
                }
              }
              name
              wordpress_id
            }
          }
        }
      `}
      render={data => <AccreditationsPass {...props} data={data} />}
    />
  );
}
