import React, { useMemo } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ProductListItem from '../Products/ProductListItem'
import Carousel from '../Carousel';
import './RelatedProducts.scss'
const RelatedProductPass = (props) => {
  
  const { data, title, listView, postfilter, className } = props
  const { allWordpressWpProducts, siteMetadata = null } = data
  const posts = useMemo(() => postfilter ? allWordpressWpProducts.nodes.filter(item => postfilter.includes(item.wordpress_id) ) : allWordpressWpProducts.nodes, [postfilter, allWordpressWpProducts]);

  if (listView) {
    const settings = {
      container: `product-feed-slider`,
      nav: true,
      controls:true,
      mouseDrag: true,
      items: 1,
      loop: false,
    };
    return (
      <section className={`post-feed product-list${className ? ` ${className}` : ''}`}>
        <div className="outer-list">
          <div className="wrap">
            <div className="inner">
              {title && <div className="intro">
                <h3 dangerouslySetInnerHTML={{__html: title}} />
              </div>}
              </div>
          </div>
        </div>
        <Carousel settings={settings} className="list">
            {posts && posts.map((post, slide_count) => post.slug !== 'gatsby' &&
              <div className="item">
                <div className="wrap"><ProductListItem key={slide_count} data={post} siteMetadata={siteMetadata} showIntro={listView ? true : false} /></div>
              </div>
            )}
        </Carousel>
      </section>
    )
  } else {
    return (
      <section className={`post-feed product-list${className ? ` ${className}` : ''}`}>
        <div className="outer-list">
          <div className="wrap">
            <div className="inner">
              {title && <div className="intro">
                <h3 dangerouslySetInnerHTML={{__html: title}} />
              </div>}
              <div className="tiles">
              {posts && posts.map((post, slide_count) => post.slug !== 'gatsby' &&
                <ProductListItem key={slide_count} data={post} siteMetadata={siteMetadata} showIntro={listView ? true : false} />
              )}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default (props) => (
  <StaticQuery
    //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
    query={graphql`
      query {
        allWordpressWpProducts {
          nodes {
            ...ProductListFields
          }
        }
      }
    `}
    render={data => <RelatedProductPass {...props} data={data} />}
  />
)
