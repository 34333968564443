import React, { Component, Fragment } from 'react';
import { decodeEntities } from '../../../utils/helpers';
import RenderContent from '../../RenderContent'
import './ProductTabs.scss';
import { ColourCharts } from '../../../components/ColourCharts'
import { Accreditations } from './Accreditations'
import { Applications } from './Applications'
const isClient = typeof window !== 'undefined';

const Item = (props) => {
  const { item, openNavKey, toggleSubNav, keyId, accreditations, applications, togglePerfomanceTab, performanceTab, colours } = props;
  const { tabTitle, otherTitle, content, performance } = item;
  const isopen = openNavKey === keyId;
  const tabTitleToUse = tabTitle.value === 'other' ? decodeEntities(otherTitle) : decodeEntities(tabTitle.label)
  return (
    <div key={keyId}>
      <div onClick={event => toggleSubNav(event, keyId)} className={`trigger ${isopen ? 'open' : 'closed'}`}>
        {tabTitleToUse}
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
      </div>
      <div className={`content ${isopen ? 'open' : 'closed'}`}>
        <h3 className="title">{tabTitleToUse}</h3>
        <div className="aside">
          <RenderContent content={content} />
          {tabTitle.value === 'colours' && <ColourCharts colours={colours} />}
          {tabTitle.value === 'accreditations' && <Accreditations accreditations={accreditations} />}
          {tabTitle.value === 'application' && <Applications applications={applications} />}
          {tabTitle.value === 'properties' &&
            <div className="properties-tabs">
              <div className="tab-triggers">
                {performance && performance.map((tab, index) =>
                  <div key={index} onClick={event => togglePerfomanceTab(event, index)} className={`trigger ${performanceTab === index ? 'open' : 'closed'}`}>{tab.title}</div>
                )}
              </div>
              <div>
                {performance && performance.map((tab, index) =>
                  <div key={index} className={`content ${performanceTab === index ? 'open' : 'closed'}`}>
                    <RenderContent content={tab.content} />
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default class ProductTabs extends Component {
  state = { openNavKey: 0, performanceTab: 0 };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  togglePerfomanceTab = (event, performanceTab) => {
    event.preventDefault();
    const { performanceTab: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === performanceTab) return this.setState({ performanceTab: null });
    return this.setState({ performanceTab });
  }

  render() {
    const { tabs, active, accreditations, applications, colours } = this.props;
    const { openNavKey, performanceTab } = this.state;
    return (
      <div className={`product-tabs ${active ? 'active' : ''}`}>

        <div className="post-list-navigation">
          <div className="inner">
            <ul>
              {tabs && tabs.map((col, index) => {
                const tabTitle = col.tabTitle.value === 'other' ? decodeEntities(col.otherTitle) : decodeEntities(col.tabTitle.label)
                return (
                  <li key={index}><span className={index === openNavKey ? 'active' : ''} onClick={event => this.toggleSubNav(event, index)}>{tabTitle}</span></li>
                )
              }
              )}
            </ul>
          </div>
        </div>

        {tabs && tabs.map((col, index) => (
          <Item key={index} keyId={index} item={col} toggleSubNav={this.toggleSubNav} togglePerfomanceTab={this.togglePerfomanceTab} openNavKey={openNavKey} performanceTab={performanceTab} accreditations={accreditations} applications={applications} colours={colours} />
        ))}

      </div>
    );
  }
}
